.adsk-viewing-viewer .adsk-control.adsk-hidden {
  display: none; }

.adsk-viewing-viewer .adsk-control:hover > .adsk-control-tooltip {
  visibility: visible; }

.adsk-viewing-viewer .adsk-control-tooltip {
  position: absolute;
  visibility: hidden;
  bottom: 120%;
  /* Position tooltip above the element */
  padding: 4px;
  z-index: 5;
  left: 0;
  font-size: 11px;
  text-align: center;
  text-transform: capitalize;
  line-height: 1.2;
  white-space: nowrap;
  opacity: 1.0;
  border-radius: 3px; }

.adsk-viewing-viewer .adsk-toolbar-vertical .adsk-control-tooltip {
  right: 120%;
  left: unset;
  bottom: 25%; }

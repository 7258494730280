.adsk-viewing-viewer .adsk-filterbox {
  position: relative; }

.adsk-viewing-viewer .adsk-filterbox input.filter-box {
  height: 34px;
  direction: ltr;
  margin: 0;
  padding: 7px 12px 8px 40px;
  font-size: 12px;
  width: 100%;
  outline: none;
  position: relative;
  /* Bootstrap Fix */
  box-sizing: border-box;
  line-height: normal;
  z-index: 1; }

.adsk-viewing-viewer .adsk-filterbox .filter-box-icon {
  left: 22px;
  top: 12px;
  width: 10px;
  height: 7px;
  position: absolute;
  z-index: 1; }

.adsk-viewing-viewer .adsk-filterbox .filter-box-close {
  right: 13px;
  top: 10px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 1;
  position: absolute; }

.adsk-viewing-viewer .adsk-filterbox.empty > .filter-box-close {
  display: none; }

.geometry {
  max-width: 200px;
  opacity: .8;
  height: 200px;
  font-family: "Helvetica Neue Light", "Segoe UI Semilight", sans-serif;
  font-size: 13px;
  color: #333333;
  display: inline-block;
  margin-left: 1em;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  cursor: pointer;
  margin-top: 10px; }

.browserview item:hover {
  opacity: 1;
  transition: all 0.5s ease; }

.browserview:hover item.selected {
  opacity: 1;
  transition: all 0.38s ease; }

.geometry label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 162px;
  max-width: 200px;
  color: #f4f4f4;
  z-index: 203;
  padding: 10px;
  background: rgba(34, 34, 34, 0.6);
  height: 72px;
  transition: all .2s ease-in-out;
  opacity: 0;
  cursor: pointer;
  line-height: 1.5;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden; }

.geometry label:hover {
  height: 200px;
  overflow: visible;
  white-space: normal;
  margin-top: -72px;
  transition: all .2s ease-in-out;
  opacity: 0; }

.geometry label:hover ~ img {
  display: block;
  margin-top: -148px;
  width: 200px;
  transition: all .2s ease-in-out; }

.flipped > .browserElement label {
  opacity: 0 !important; }

.browserview > item:hover label {
  opacity: 1;
  filter: alpha(opacity=50); }

.geometry img {
  display: block;
  margin-top: -92px;
  width: 200px;
  transition: all .2s ease-in-out; }

.geometry img:hover {
  cursor: pointer; }

.browserview > item.selected, .browserview > item.selected label {
  opacity: 1;
  transition: all 0.2s ease-in-out; }

.geometry .selected:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.15); }

.browserVisible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear; }

.browserHidden {
  visibility: hidden;
  opacity: 0;
  height: 0;
  overlfow: hidden;
  /*transition: visibility 0s 1s, opacity 1s linear;*/ }

/*NEW FLIP JAZZ*/
.cameraview {
  padding: 6px;
  padding-left: 28px;
  margin: 1px 2px 0px 2px;
  background-color: rgba(224, 224, 224, 0.8);
  transition: all 0.2s ease-in-out;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjUxNDMxOERGQjQzRTExRTNBMTcxRkZFNEJEN0E2NjU2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjUxNDMxOEUwQjQzRTExRTNBMTcxRkZFNEJEN0E2NjU2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NTE0MzE4RERCNDNFMTFFM0ExNzFGRkU0QkQ3QTY2NTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NTE0MzE4REVCNDNFMTFFM0ExNzFGRkU0QkQ3QTY2NTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6dltUsAAAAvElEQVR42mL8//8/AyWAiYFCQLEBLCCira3tJZD6DhVjBGI+KI0MVldVVaUC1cL4qkCsBHPBRiDWh2JbIGYDYn40HA1VywnEKUB8DIibWaCCf6AYBH4jsZHBKyjdBcQhQCwCxFdhBiQBcRSSYl4sBvyD0jxA/APK/g8zgB2KiQF/QRoHJBphtnJDA5lkA7ig9HIgvg9lc7CQYIAElN4ExEeAuAGUXmAGHCDCgK9I7HdAXAoylHHoZyaAAAMA0EImBepZpP0AAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: 5px 5px; }

.cameraview-selected {
  border-left: 3px solid #999999;
  transition: all 0.2s ease; }

.cameraview:hover {
  background-color: rgba(204, 204, 204, 0.8); }

.geometry {
  width: 200px;
  height: 200px;
  position: relative;
  perspective: 800px;
  -webkit-perspective: 800;
  -moz-perspective: 800;
  -ms-perspective: 800;
  -o-perspective: 800;
  border: 1px solid rgba(204, 204, 204, 0.8);
  background-color: #f4f4f4;
  overflow: hidden; }

.adsk-viewing-viewer .card {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transition: transform .38s ease-in; }

/*front*/
.browserElement {
  display: block;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  overflow: hidden;
  transform: perspective(800px) rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transition: all .38s; }

/*back*/
.wrappercam {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  background-color: rgba(255, 255, 255, 0.7);
  display: block;
  position: relative;
  top: -200px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: perspective(800px) rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transition: all .38s; }

.cameraviews {
  height: calc( 100% - 44px) !important;
  transition: all .38s; }

.card .cameraviews {
  overflow-Y: scroll; }

.flipped .browserElement {
  transform: perspective(800px) rotateX(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg); }

.flipped .wrappercam {
  transform: perspective(800px) rotateX(0deg);
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg); }

.wrappercam p {
  font-weight: bold;
  margin: 15px 6px 15px 10px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden; }

.viewsbtn {
  width: 40px;
  height: 14px;
  padding: 6px;
  color: #f4f4f4;
  background-color: rgba(34, 34, 34, 0.8);
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  border-radius: 16px;
  transition: all .38s ease-in-out;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkY3OUM5RjIyQjlCNTExRTM4MzIyREIzMEE0OTQxMjA3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkY3OUM5RjIzQjlCNTExRTM4MzIyREIzMEE0OTQxMjA3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Rjc5QzlGMjBCOUI1MTFFMzgzMjJEQjMwQTQ5NDEyMDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Rjc5QzlGMjFCOUI1MTFFMzgzMjJEQjMwQTQ5NDEyMDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6o3qeAAAAAsElEQVR42mL8//8/A7UAEwMVweA1jOXr16/hQHoqEAsTof4tEGcD8UqYADc3N1yS8cuXLx+AND8JDvgIxALIhgEdJA1kzmJCMkgPZDga1sNiGIrFQIMSgNRVIPYCuQyWNhhxuARb2mGEijMiyyNHgC4WTbokRQAS+9JoomXAEQkgkAhNLmDDPpJoCIp6YDpbAKS0gXgbyLB0aMpmIDIHpKMLAg18CsTejKNFEMkAIMAAglkqhjcW+DoAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: 25px 4px; }

.viewsbtn:hover {
  background-color: rgba(96, 96, 96, 0.8);
  transition: all .38s ease-in-out; }

.flipped ~ .viewsbtn {
  width: 14px;
  transition: all .38s ease-in-out;
  background-image: none;
  background-position: 35px 4px; }

.viewsbtn p {
  margin: 0px;
  margin-left: 3px;
  text-align: left;
  font-weight: bold;
  transition: all .38s ease-in-out; }

.adsk-viewing-viewer #count {
  opacity: 1; }

.adsk-viewing-viewer #close {
  opacity: 0;
  position: relative;
  top: -16px;
  text-shadow: 0px 1px 1px #333; }

.flipped ~ .viewsbtn > #count {
  opacity: 0; }

.flipped ~ .viewsbtn > #close {
  opacity: 1; }

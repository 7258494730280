.adsk-viewing-viewer .adsk-lmv-tftable {
  width: 100%;
  border-spacing: 1px;
  border-style: none; }

.adsk-viewing-viewer .adsk-lmv-tftable tr.logical-group td {
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  border: none; }

.adsk-viewing-viewer .adsk-lmv-tftable tr.logical-group:first-child {
  height: 65px; }

.adsk-viewing-viewer .adsk-lmv-tftable tr.logical-group:first-child td {
  padding-top: 15px;
  padding-bottom: 0px; }

.adsk-viewing-viewer .adsk-lmv-tftable tr.logical-group td div {
  padding-left: 10px;
  text-transform: capitalize; }

.adsk-viewing-viewer .adsk-lmv-tftable tr td {
  height: 36px;
  padding: 0px 10px 0px 16px;
  font-size: 12px;
  border: none; }

.adsk-viewing-viewer .adsk-lmv-tftable tr td div {
  padding-left: 10px; }

.adsk-viewing-viewer .adsk-lmv-tftable.adsk-lmv-settings td:last-child {
  width: 30px;
  height: 30px;
  padding: 0px; }

.adsk-viewing-viewer .adsk-lmv-tftable input {
  margin: 0px 8px; }

.adsk-viewing-viewer .adsk-lmv-tftable input:disabled {
  color: #777777;
  background-color: #dddddd; }

.adsk-viewing-viewer .adsk-lmv-tftable input[type="range"]:disabled {
  background-color: #aaaaaa; }

.adsk-viewing-viewer .adsk-lmv-tftable tr.switch-slider-row td {
  padding-top: 13px;
  padding-bottom: 13px;
  vertical-align: top; }

.adsk-viewing-viewer .adsk-lmv-tftable tr.switch-slider-row td:nth-child(1) {
  width: 150px; }

.adsk-viewing-viewer .adsk-lmv-tftable tr.switch-slider-row td:nth-child(1) div {
  font-weight: 600; }

.adsk-viewing-viewer .adsk-lmv-tftable tr.switch-slider-row td:nth-child(2) {
  padding-left: 0px;
  padding-right: 0px;
  width: 350px; }

.adsk-viewing-viewer .adsk-lmv-tftable tr.switch-slider-row td:nth-child(2) div {
  padding-left: 0px;
  font-weight: 300; }

.adsk-viewing-viewer .adsk-lmv-tftable tr.switch-slider-row td:nth-child(3) {
  width: 20px;
  padding: 13px 16px 13px 59px; }

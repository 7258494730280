.adsk-viewing-viewer .settings-tabs {
  height: 40px;
  width: 100%;
  position: absolute;
  z-index: 1; }

.adsk-viewing-viewer .settings-tabs ul {
  width: 100%;
  height: 100%;
  position: absolute; }

.adsk-viewing-viewer .settings-tabs ul,
.adsk-viewing-viewer .settings-tabs ul li {
  list-style: none;
  margin: 0;
  padding: 0; }

.adsk-viewing-viewer .settings-tabs ul li {
  width: 25%;
  height: 100%;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  background-color: rgba(0, 0, 0, 0);
  white-space: nowrap;
  min-width: 50px;
  text-overflow: clip;
  cursor: pointer;
  display: inline-block; }

.adsk-viewing-viewer .for-2d-model ul li,
.adsk-viewing-viewer .for-2d-model ul li a {
  width: 33.33% !important; }

.adsk-viewing-viewer .for-2d-model ul li:first-child a {
  text-align: right !important; }

.adsk-viewing-viewer .for-2d-model ul li:last-child a {
  text-align: left !important; }

.adsk-viewing-viewer .settings-tabs ul li a {
  width: 25%;
  height: 100%;
  text-decoration: none;
  text-align: center;
  position: absolute; }

.adsk-viewing-viewer .settings-tabs ul li:last-child a {
  text-align: center; }

.adsk-viewing-viewer .settings-tabs span {
  top: 16px;
  padding-bottom: 2px;
  font-size: 12px;
  border: solid;
  border-color: transparent;
  border-width: 0px 0px 3px 0px;
  font-weight: bold;
  position: relative; }

.adsk-viewing-viewer .settings-tabs .dockingPanel .settings-table td, .dockingPanel .settings-table td > input {
  cursor: pointer;
  display: none; }

.adsk-viewing-viewer .settings-tabs table.settings-table td:last-child {
  width: 30px;
  height: 30px;
  padding: 0px; }

.adsk-viewing-viewer .settings-tabs-tables-container {
  min-height: calc(100% - 76px); }

.adsk-viewing-viewer .settings-tabs-tables-container .adsk-lmv-tftable {
  display: none; }

.adsk-viewing-viewer .settings-tabs-tables-container .settings-selected-table {
  display: block; }

.adsk-viewing-viewer .docking-panel.hud {
  display: block;
  position: absolute;
  min-width: 250px;
  width: 180px;
  top: calc(50% - 50px);
  left: 50%;
  width: 350px;
  height: 200px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%); }

.adsk-viewing-viewer .hud-client {
  width: 100%;
  position: relative; }

.adsk-viewing-viewer .hud-message {
  display: block;
  min-height: 12px;
  padding: 10px 15px;
  font-size: 12px; }

.adsk-viewing-viewer .hud-button {
  position: relative; }

.adsk-viewing-viewer .hud-checkbox {
  margin-left: 20px !important;
  margin-bottom: 10px !important; }

.adsk-viewing-viewer .alert-box {
  font-family: "Helvetica Neue Light", "Segoe UI Semilight", sans-serif;
  display: block;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  height: auto;
  max-width: 40%;
  background: rgba(250, 250, 250, 0.9);
  padding: 10px;
  visibility: hidden;
  border-radius: 5px;
  border-width: 1px;
  border-color: #b8b8b8;
  border-style: solid;
  white-space: -moz-pre-wrap;
  /* Mozilla */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */
  word-break: break-all;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%); }

.adsk-viewing-viewer .alert-box-image {
  display: block;
  float: left;
  left: 10px;
  top: 10px;
  width: 60px;
  height: 60px;
  padding-right: 10px; }

.adsk-viewing-viewer .alert-box-msg {
  display: block;
  color: #333333;
  margin-left: 70px;
  margin-top: 2px; }

.adsk-viewing-viewer .alert-box-title {
  display: block;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 18px;
  min-height: 18px;
  margin-bottom: 10px; }

.adsk-viewing-viewer .alert-box-text {
  display: block;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 12px;
  min-height: 12px; }

.adsk-viewing-viewer .alert-box-content {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  min-height: 12px;
  list-style: disc; }

.adsk-viewing-viewer .alert-box-ok {
  display: block;
  position: relative;
  z-index: 2;
  width: 50px;
  height: auto;
  left: 50%;
  margin-left: -25px;
  margin-top: 10px;
  bottom: 5%;
  line-height: 20px;
  text-align: center;
  color: #000;
  font-size: 14px;
  background: rgba(0, 0, 0, 0);
  border-radius: 2px;
  border-width: 1px;
  border-color: #999999;
  border-style: solid;
  padding: 3px;
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }

.adsk-viewing-viewer .alert-box-ok:hover {
  color: #111111;
  background: white; }

/* Multiple errors display */
.adsk-viewing-viewer .alert-box.errors {
  max-height: 200px; }

.adsk-viewing-viewer .alert-box-msg.errors {
  max-height: 150px;
  padding-right: 15px;
  overflow-y: auto; }

.adsk-viewing-viewer .alert-box-title.errors {
  margin-top: 15px; }

.adsk-viewing-viewer .alert-box-text.errors {
  margin-top: 5px; }

.adsk-viewing-viewer .alert-box-image.img-reload {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAATlBMVEUAAAC8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLwXZW//AAAAGXRSTlMADdNH4Xntk8apUvNrMPqdZSAGuz2FFF2i4n3MhQAAAXVJREFUSMfdl9tuwyAMQDGQ0FByI+nF//+jm7Ywt4VA8KRJ63mNjrDBGEf8GwC/EEU8fOLrZVjduEjdtlouo1vhuOyVkfiENMofkmE6YYLTBGXZStxB2oI8d5ihm3Oy0phFq33ZnbHAudmTm5dlusbapnsOZghyztVGwbb7yjz6afk20Dc5XcUD10nm5b6lVR1Eh+90RgaqjPssElzHSI4TbqfXRb/x4AaS00EPNn0Hczn/xHQR1XJ/DuUP9fIlBL2KahnCMY6iXlahcnuGbMKVE/XMoUAUQ1bbIUuoVqm6TL1KKVuO3G17PXPkZUvZ/0JexF+uzM+Zv9v8c+ZXGBjFr22FuKhk0t3BzT3dfOk+E3G/kqrQSYi4Ueq11MNi1oGabLl7Jp4Vyq/ct1NNFsfSixFjQ9BtX3irYqYWNxpR+UrOd0TaGMb7TEFzJ4PhJoiKmYQSZkxD5PLmMCcCjAlQBDizJ8GYegnGvE0wJn2i4h/jnfgAT9VrCTWL7i0AAAAASUVORK5CYII=");
  background-repeat: no-repeat; }

.adsk-viewing-viewer .alert-box-image.img-unsupported {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAUVBMVEUAAAC8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLzYhxYwAAAAGnRSTlMA8Q73gDv9BiUazrVL6VrDbC+oeuDYkoqfYnTM6tcAAAFySURBVEjH7ZTbkoIwEAVNQgiBoNyR8/8fuoi6szEUZlL7pv1MKzVMz+nLP6I7neyaEqVMlXsAfaJb1ABEmyZ3uFEluQ53zgmuLB/yaPlyhicT220FnijNlSsQF6Z7xl8almtHT64LjjzD58oJQgHIV5RSQmDFMadVaq2LojBnAIxAFmzPG5pdfCB2wI3h8WcNwAhkwoaQFGZ0IDrHhipo8rGBmAvu5I+tvDICafDEUdYbU9T58N+zAr3KG67BRl8oEBN3PujTmjE2EFMCL0dADrGB9GENUoDooqZFa2EVQLh3x9afT0vyYSAOHqP5XTgiOzy2RC39XzwMJIOPsFQkUR0dW0K1/roeBVLhhdwZKYP3wWAPji2hxIrCK9PesY0lDGRGLGEgWu0802crs0JAE0wrIAt2ez+QBbFyGIgdouUwkAlcmQLROVtGRsd2l/kkVyzJ4e7S9oYLtpFjl4rORwLL6soOXCgQVyuRRL6cvnwGPzKpczNf1iTWAAAAAElFTkSuQmCC");
  background-repeat: no-repeat; }

/* Keep 'img-unloack' for now to support the old notation */
.adsk-viewing-viewer .alert-box-image.img-unlock,
.adsk-viewing-viewer .alert-box-image.img-unloack {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAVFBMVEUAAAC8vLy8vLy8vLy9vb28vLy/v7+8vLy9vb28vLy9vb27u7u8vLy9vb28vLy8vLy8vLy9vb28vLy9vb28vLy8vLy8vLy7u7vFxcW/v7+8vLy+vr4GHu50AAAAGnRSTlMADpDTFeJM/vBFMAf2YciIe+nbu7GgbmtVNgXq938AAAEkSURBVEjH7ZTbdoMgEEVxuCgqEK9JJ///n21WYGFjRMA+tF3uR4ft4GGEbMAEDLSrOjqAYCSJGjh6ONTxKoMKv1NBbHfR45peRLlzi+9o5wj3gltcdl1Vbsql2ou5WyymoIAuXtaFQ28GH/Akm8cTOfnohya4aXSMvks9okOFGmsX7ZUsuboD0IHWN/eB5qVg0CIC40GfSyh7nTlq5Sm0bzG2d0S1GUZfhA/LVG9WFLZ1+UHCMEkc66++kQykzXLOkQvuBjyDhv5CudiFWdnYtQsZkznlU37AQTImgefIWtiLTafLXLqi4Mky+CrEy+ubTiZ3LnyVpcr3I3JZH9h2aQ4Ehrw+cFSorS111ng+azzvx7C1U/7nclt90f61q/eUf1z+BAQm0DzJD1vhAAAAAElFTkSuQmCC");
  background-repeat: no-repeat; }

.adsk-viewing-viewer .alert-box-image.img-item-not-found {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAV1BMVEUAAAC8vLy/v7/c3Nzk5OT///+8vLy8vLy8vLy8vLy8vLy8vLy8vLz///+8vLz///+7u7u4uLjJycnW1tb+/v7n5+e/v7/z8/PQ0NDt7e3ExMT5+fnc3NyZffqfAAAADnRSTlMA8A8R/fHbyh69Ku4xHnuV7q4AAAEzSURBVEjH7dTZboMwEIVhCqRJt+EMi1fe/zk7TnFQZTWBSW8i5b+yBJ9GNjKVRDerq+pUlW3Gx/oOTG/1HVi0Hotu1DhrLaZjo8eiT0qctR6LVuFV6zG9nm5ggEFGIlkseNXv1zAQ3Dx5K/nWGV5xqQvrfOzW4vCScalLPHW/a5v80qo348MFr/rzKo6+nSdb4NzHFWyHQGCYIS646C88+8Ewzkt2O3D2uCys4K+mLipxGbdp9KEvHmzCc5dyCpyPflRNRogJx6DBsuXUBAVmN56x490Y7GIevBcD889cG/ZicO+XO9mD9mEYGbvMZdqJeciX2YCU2DqAlNgaJtLiCaTHnh4MIwyS02ECS9DilBaHPmVUGG03jt3YQ4XTv+sBsXLP+tPWf+eyJ37if8YvG0v4G00xpazwgfUzAAAAAElFTkSuQmCC");
  background-repeat: no-repeat; }

/* max-height - the only parameter in this file that needs to be edited.
 * Change it to suit your needs. The rest is recommended to leave as is.
 */
.adsk-viewing-viewer .datatable .clusterize-scroll {
  max-height: 200px;
  overflow: auto; }

.adsk-viewing-viewer .datatable table {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 0; }

.adsk-viewing-viewer .datatable .subrow {
  font-size: 0;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  /* transition:font-size 400ms; */ }

.adsk-viewing-viewer .datatable .parentrow {
  background-color: #4a4747; }

.adsk-viewing-viewer .datatable table td,
.adsk-viewing-viewer .datatable table th {
  border: 1px solid #000;
  white-space: nowrap; }

.adsk-viewing-viewer .datatable .table-striped > tbody > tr:nth-child(2n+1) > td,
.adsk-viewing-viewer .datatable .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #4a4747; }

.adsk-viewing-viewer .datatable td > span {
  float: right;
  width: 1em;
  height: 1em;
  position: relative;
  margin-right: 3px;
  margin-top: 4px;
  margin-left: 5px;
  white-space: nowrap; }

.adsk-viewing-viewer .datatable td > span:before {
  content: '';
  position: absolute;
  width: 80%;
  height: 0;
  left: 10%;
  top: 50%;
  margin-top: -1px;
  border-top: 2px solid black;
  border-color: inherit; }

.adsk-viewing-viewer .datatable td > span.collapsed:after {
  content: '';
  position: absolute;
  height: 80%;
  width: 0;
  margin-left: -1px;
  border-left: 2px solid black;
  border-color: inherit;
  left: 50%;
  top: 10%; }

.adsk-viewing-viewer .datatable td > span:hover {
  border-color: orange; }

.adsk-viewing-viewer .datatable .aggregate-headers {
  overflow-x: scroll; }

.adsk-viewing-viewer .datatable .aggregateArea td {
  font-size: 16px !important;
  font-family: initial !important;
  text-align: center; }

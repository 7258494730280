.adsk-viewing-viewer .model-structure-panel {
  width: 300px;
  height: 250px;
  min-width: 250px;
  min-height: 100px; }

.adsk-viewing-viewer .treeview {
  left: 4px;
  position: relative; }

.adsk-viewing-viewer .treeview .group,
.adsk-viewing-viewer .treeview .leaf {
  width: 100%;
  height: 29px;
  direction: ltr;
  position: relative; }

.adsk-viewing-viewer .treeview .group.selected,
.adsk-viewing-viewer .leaf.selected {
  border: none; }

.adsk-viewing-viewer .treeview lmvheader {
  white-space: nowrap;
  word-wrap: break-word;
  width: calc(100% - 7px);
  display: inline-block;
  border-radius: 4px;
  /* box-shadow: 0px 3px 5px rgba(0,0,0,0.2); */ }

.adsk-viewing-viewer .treeview .leaf.selected {
  /*border-left: 3px solid #5085bf;*/
  border: none; }

.adsk-viewing-viewer .exclude > lmvheader {
  display: none; }

.adsk-viewing-viewer .exclude {
  padding-left: 0;
  left: 0; }

.adsk-viewing-viewer .treeview {
  margin-left: 0px;
  width: 100%; }

.adsk-viewing-viewer .treeview group {
  position: relative;
  font-size: 14px;
  color: #333333;
  display: block;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  cursor: pointer;
  display: inline; }

.adsk-viewing-viewer .treeview group.category,
.adsk-viewing-viewer .treeview .group.category {
  height: 40px; }

.adsk-viewing-viewer .treeview group.category,
.adsk-viewing-viewer .treeview .group.category,
.adsk-viewing-viewer .treeview leaf.category,
.adsk-viewing-viewer .treeview .leaf.category {
  font-weight: bold; }

.adsk-viewing-viewer .treeview group.property,
.adsk-viewing-viewer .treeview .group.property,
.adsk-viewing-viewer .treeview leaf.property,
.adsk-viewing-viewer .treeview .leaf.property {
  font-weight: 500;
  font-size: 12px; }

.adsk-viewing-viewer .treeview group:hover {
  cursor: pointer; }

.adsk-viewing-viewer .treeview lmvheader {
  width: 100%;
  margin-bottom: 1px;
  padding: 6px;
  white-space: nowrap;
  display: inline-block; }

.adsk-viewing-viewer .treeview leaf {
  width: 100%;
  display: block;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none; }

.adsk-viewing-viewer .treeview group.selected > lmvheader,
.adsk-viewing-viewer leaf.selected > lmvheader {
  background-color: #e7e7e7; }

.adsk-viewing-viewer .treeview group.selected > leaf {
  background-color: #e7e7e7; }

.adsk-viewing-viewer .treeview group.selected,
.adsk-viewing-viewer .treeview leaf.selected {
  border-left: 3px solid #999999;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s ease; }

.adsk-viewing-viewer .treeview group.collapsed > leaf,
.adsk-viewing-viewer .treeview group.collapsed > group {
  display: none; }

/* Bootstrap Fix */
.adsk-viewing-viewer .treeview label {
  margin-bottom: 0px;
  font-weight: normal; }

.adsk-viewing-viewer .treeview lmvheader {
  line-height: normal; }

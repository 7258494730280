/**
 * Little panel on top of the screen with an attribute name and a value.
 **/
.adsk-viewing-viewer .message-panel table {
  display: block;
  /* Safari bug wth border-radius - unless we specify block here it does not work SPK-1032 */
  position: relative;
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 0px; }

.adsk-viewing-viewer .message-panel tbody {
  width: 100%;
  position: absolute; }

.adsk-viewing-viewer .message-panel tr {
  width: 100%;
  position: absolute; }

.adsk-viewing-viewer .message-panel .name {
  width: calc(60% - 10px);
  padding-left: 10px;
  text-align: left;
  display: inline-block;
  position: relative; }

.adsk-viewing-viewer .message-panel .value {
  width: calc(40% - 10px);
  padding-right: 10px;
  text-align: right;
  display: inline-block;
  position: absolute; }

.adsk-viewing-viewer .message-panel td {
  line-height: 34px;
  vertical-align: middle; }

/**
 * Little button bar on top of the screen
 **/
.adsk-viewing-viewer .focal-length {
  left: 50%;
  top: 40px;
  width: 160px;
  height: 34px;
  min-width: 0;
  min-height: 0;
  transform: translateX(-50%);
  position: absolute; }

[tooltipText*="Inspect"] {
  left: 100%; }

/* original icon: res/icons/layers_24.png */
.adsk-viewing-viewer .toolbar-layers-tool {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAJ1BMVEUAAADS1dbS1dbS1dbS1dbS1dbS1dbS1dbS1dbS1dbS1dbS1dbS1dbKDmVBAAAADHRSTlMA8NCgYDAgEJBQsHC3H/WiAAAAT0lEQVQY02MgAdicAYFDYDbHGTBQB3N8wOyDCSA2J0RCFCwRCOFMALHZZMDsI2tABhRBJJrBBuiA2Yf3AA2AARZaGgBkwg1oADLhBpAQFgDkeF4dRV5pxwAAAABJRU5ErkJggg=="); }

.adsk-viewing-viewer .toolbar-menu-collapsed .toolbar-subtoolbar:first-child,
.adsk-viewing-viewer .toolbar-menu-collapsed .toolbar-subtoolbar:nth-child(2),
.adsk-viewing-viewer .toolbar-menu-collapsed .toolbar-subtoolbar:nth-child(n+3) {
  display: none; }

/* Settings sub-menu CSS. Should be reviewed and probably have classes renamed */
.adsk-viewing-viewer .toolbar-settings-sub-menu {
  min-width: 300px;
  position: absolute;
  display: block;
  left: 0;
  bottom: 40px;
  margin-bottom: 10px;
  background: #222222;
  list-style: none;
  font-size: 14px;
  color: #ffffff;
  line-height: 14px;
  text-align: left;
  -webkit-padding-start: 0;
  -moz-padding-start: 0;
  -ms-padding-start: 0;
  border: 1px solid rgba(150, 150, 150, 0.15);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: all 0.2s ease; }

.adsk-viewing-viewer .toolbar-settings-sub-menu.adsk-hidden {
  display: block;
  visibility: hidden;
  opacity: 0;
  /*transition: all 0.2s ease 0.5s;*/
  /*comment it for fusion-like*/ }

.adsk-viewing-viewer .toolbar-submenu .toolbar-submenu-checkbox {
  width: 14px;
  height: 14px;
  margin: 0 5px 0 0;
  padding: 0;
  cursor: pointer; }

.adsk-viewing-viewer .toolbar-submenu .toolbar-submenu-horizontal-divider {
  height: 1px;
  border-top: 1px solid black; }

.adsk-viewing-viewer .toolbar-submenu .toolbar-submenu-listitem {
  position: relative;
  width: calc(100% - 40px);
  padding: 20px 20px 20px 20px; }

.adsk-viewing-viewer .toolbar-submenu .toolbar-submenu-listitem:hover {
  background-color: rgba(166, 194, 255, 0.8);
  border-radius: 4px; }

.adsk-viewing-viewer .toolbar-submenu .toolbar-submenu-select {
  display: block;
  position: relative;
  width: calc(100% - 40px);
  padding: 20px 20px 0 20px; }

.adsk-viewing-viewer .toolbar-submenu .toolbar-submenu-selectlabel {
  position: relative;
  display: block;
  padding-bottom: 4px;
  cursor: default; }

/* End of settings sub-menu CSS */
.adsk-viewing-viewer .toolbar-menu div > li {
  float: left;
  padding: 4px 4px 4px 4px; }

.adsk-viewing-viewer .docking-panel .adsk-lmv-tftable {
  padding: 0; }

.adsk-viewing-viewer .statspanel {
  display: block;
  position: absolute;
  z-index: 1;
  left: 4px;
  bottom: 8px;
  height: 80px;
  width: 220px;
  color: #eeeeee;
  font-family: "consolas","courier","monospace";
  font-size: 14px;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden; }

.adsk-viewing-viewer .fps {
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: #000000;
  font-family: "consolas","courier","monospace";
  font-size: 14px;
  visibility: hidden;
  z-index: 1; }

.adsk-viewing-viewer .option-drop-down {
  width: 160px;
  font-size: 11px; }

.adsk-viewing-viewer .option-drop-down.tabcell {
  left: 20px;
  width: calc(100% - 45px);
  position: relative; }

.adsk-viewing-viewer .adsk-toolbar .adsk-button > .toolbar-vertical-group {
  position: absolute;
  bottom: 125%;
  left: -5px;
  margin: 0;
  transition: all 0.2s ease;
  z-index: 1; }

.adsk-viewing-viewer .adsk-toolbar.adsk-toolbar-vertical .adsk-button > .toolbar-vertical-group {
  bottom: -5px;
  right: 50px;
  left: unset; }

.adsk-viewing-viewer .adsk-toolbar .adsk-button > .toolbar-vertical-group:last-child {
  margin-top: 0; }

.adsk-viewing-viewer .adsk-toolbar .adsk-button > .toolbar-vertical-group.adsk-hidden {
  display: block;
  visibility: hidden;
  opacity: 0;
  /*transition: all 0.2s ease 0.5s;*/
  /*comment it for fusion-like*/ }

.adsk-viewing-viewer .adsk-control-tooltip {
  pointer-events: none; }

.adsk-viewing-viewer .notouch .adsk-toolbar .adsk-button > .toolbar-vertical-group:hover ~ .adsk-control-tooltip {
  visibility: hidden; }

.adsk-viewing-viewer .adsk-toolbar .adsk-button > .toolbar-vertical-group ~ .adsk-control-tooltip {
  left: 120%; }

.adsk-viewing-viewer .adsk-toolbar.adsk-toolbar-vertical .adsk-button > .toolbar-vertical-group ~ .adsk-control-tooltip {
  left: unset;
  right: 120%;
  top: unset;
  bottom: -25px; }

.adsk-viewing-viewer .adsk-toolbar .adsk-button > .toolbar-vertical-group > .adsk-button > .adsk-control-tooltip {
  left: 120%;
  bottom: 25%;
  top: unset; }

.adsk-viewing-viewer .adsk-toolbar.adsk-toolbar-vertical .adsk-button > .toolbar-vertical-group > .adsk-button > .adsk-control-tooltip {
  left: unset; }

.adsk-viewing-viewer .explode-submenu {
  min-width: 0;
  min-height: 0;
  width: 160px;
  height: 34px;
  position: absolute;
  left: 21px;
  bottom: 55px;
  cursor: default;
  transform: translateX(-50%); }

.adsk-viewing-viewer .explode-submenu.ios {
  left: 50%;
  bottom: 62px;
  width: 80%; }

.adsk-viewing-viewer .explode-submenu.android {
  left: 50%;
  bottom: 62px;
  width: 80%; }

.adsk-viewing-viewer input[type="range"].explode-slider {
  width: calc(100% - 20px);
  display: block;
  padding: 0;
  margin: 10px;
  height: 14px;
  cursor: col-resize; }

.adsk-viewing-viewer input[type=range].explode-slider::-ms-tooltip {
  display: none; }

.adsk-viewing-viewer #toolbar-inspectTools .explode-submenu {
  left: 55px;
  bottom: 0;
  margin: 0; }

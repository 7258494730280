.adsk-viewing-viewer .adsk-search-results {
  position: absolute;
  top: 34px;
  left: 0;
  width: 550px;
  height: 450px;
  min-width: 250px;
  min-height: 70px;
  max-height: 400px;
  margin: 0;
  overflow: hidden;
  border-radius: 0; }

.adsk-viewing-viewer .adsk-search-results.no-content {
  min-height: 105px; }

.adsk-viewing-viewer .adsk-search-results .docking-panel-scroll {
  top: 0;
  left: 0;
  height: calc(100% - 20px);
  position: absolute; }

.adsk-viewing-viewer .adsk-search-results.no-content .docking-panel-scroll {
  overflow: hidden; }

.adsk-viewing-viewer .adsk-search-results-scrolling-panel .search-result-container {
  padding-top: 6px;
  padding-left: 20px;
  white-space: nowrap;
  overflow-x: hidden; }

.adsk-viewing-viewer .adsk-search-results-scrolling-panel .search-result {
  height: 50px; }

.adsk-viewing-viewer .adsk-search-results-scrolling-panel .no-results-container {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px; }

.adsk-viewing-viewer .adsk-search-results-scrolling-panel .no-results-title {
  font-size: 18px; }

.adsk-viewing-viewer .adsk-search-results-scrolling-panel > div[lmv-nodeid]:hover {
  cursor: pointer; }

.adsk-viewing-viewer .adsk-search-results-scrolling-panel .search-path {
  padding-top: 2px;
  font-size: 11px;
  text-overflow: ellipsis;
  display: block;
  width: calc(100% - 5px);
  text-overflow: ellipsis;
  overflow: hidden; }

.adsk-viewing-viewer .viewer-settings-panel {
  border-radius: 0;
  top: 5px;
  left: 50%;
  border-radius: 6px; }

.adsk-viewing-viewer .viewer-settings-panel .docking-panel-title {
  border-radius: 6px 6px 0 0; }

.adsk-viewing-viewer .viewer-settings-panel.docking-panel .docking-panel-scroll.right {
  top: 90px;
  height: calc(100% - 60px);
  position: absolute; }

.adsk-viewing-viewer .viewer-settings-panel.docking-panel .restore-defaults-container {
  position: absolute;
  width: fit-content;
  width: -moz-max-content;
  display: table;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent; }

.adsk-viewing-viewer .viewer-settings-panel.docking-panel .docking-panel-tertiary-button {
  margin: 20px 0px 20px 0px;
  padding: 0 10px; }

.adsk-viewing-viewer .viewer-settings-panel .adsk-lmv-tftable tr td {
  padding-left: 10px; }

.adsk-viewing-viewer .viewer-settings-panel .environments-container {
  padding-top: 15px;
  position: relative; }

.adsk-viewing-viewer .viewer-settings-panel .environments-lighting-table {
  position: relative;
  width: calc( 100% - 44px);
  left: 20px; }

.adsk-viewing-viewer .viewer-settings-panel .settings-row-title {
  font-weight: 600; }

.adsk-viewing-viewer .viewer-settings-panel .settings-environment-cell {
  height: 36px;
  width: 100%;
  display: block;
  text-align: left;
  cursor: pointer; }

.adsk-viewing-viewer .viewer-settings-panel .settings-environment-image {
  left: 4px;
  top: 4px;
  width: 40px;
  height: 24px;
  position: relative;
  display: inline-block; }

.adsk-viewing-viewer .viewer-settings-panel .settings-environment-name {
  position: relative;
  font-size: 10px;
  font-weight: 700;
  left: 11px;
  top: -4px; }

.adsk-viewing-viewer .viewer-settings-panel .settings-container {
  padding-top: 15px;
  position: relative; }

.adsk-viewing-viewer .viewer-settings-panel .settings-table {
  position: relative;
  width: calc( 100% - 20px);
  left: 10px; }

.adsk-viewing-viewer .viewer-settings-panel .settings-cell {
  width: 100%;
  display: block;
  text-align: left;
  cursor: pointer; }

.adsk-viewing-viewer .viewer-settings-panel .settings-name {
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-left: 5px; }

.adsk-viewing-viewer .viewer-settings-panel .settings-description {
  display: block;
  font-size: 11px;
  font-weight: 700;
  margin-left: 13px;
  margin-bottom: 7px; }

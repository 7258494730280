@charset "UTF-8";
.adsk-button-arrow {
  position: absolute;
  top: -5px;
  right: -5px; }

.adsk-button-arrow > .adsk-button-icon {
  font-size: 18px; }

.adsk-button-arrow > .adsk-button-icon:before {
  content: '\25B4';
  padding: 0 5px; }

.adsk-toolbar.adsk-toolbar-vertical .adsk-button-arrow {
  left: -5px;
  right: unset; }

.adsk-toolbar.adsk-toolbar-vertical .adsk-button-arrow > .adsk-button-icon:before {
  content: '\25C2'; }

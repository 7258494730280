.adsk-viewing-viewer {
  background: #ededed;
  font-family: "ArtifaktElement", "Helvetica Neue", "Segoe UI Semilight", sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  z-index: 1; }

/* Don't let third party CSS override the box-sizing within the viewer. */
.adsk-viewing-viewer * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.adsk-viewing-viewer.viewer-fill-browser {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10; }

.adsk-viewing-viewer highlight {
  background-color: #FF9;
  color: #555; }

/* Override alertBox settings for breaking the words */
.adsk-viewing-viewer .viewer-container .alert-box-title,
.adsk-viewing-viewer .viewer-container .alert-box-text,
.adsk-viewing-viewer .viewer-container .alert-box-content {
  word-wrap: normal;
  word-break: normal; }

/* Forge logo bottom right */
.adsk-viewing-viewer .forge-logo-canvas {
  animation: fadeIn ease 1.5s;
  -webkit-animation: fadeIn ease 1.5s;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 35px;
  text-align: right;
  position: absolute;
  border-top: 2px solid rgba(128, 128, 128, 0.4); }

.adsk-viewing-viewer .forge-logo-canvas img {
  transform: scale(0.4);
  position: relative;
  bottom: 5px;
  right: -90px; }

/* Loading spinner CSS below */
.adsk-viewing-viewer .spinner {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 25%;
  visibility: visible;
  text-align: center;
  height: 12.5%; }

.adsk-viewing-viewer .spinner > div {
  width: 12%;
  height: 0;
  padding-bottom: 12%;
  margin: 0 1.5%;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.adsk-viewing-viewer .spinner .bounce1 {
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s; }

.adsk-viewing-viewer .spinner .bounce2 {
  -webkit-animation-delay: -0.50s;
  animation-delay: -0.50s; }

.adsk-viewing-viewer .spinner .bounce3 {
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s; }

@-webkit-keyframes bouncedelay {
  0%, 95%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes bouncedelay {
  0%, 95%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

/* Forge Loading spinner CSS below */
.adsk-viewing-viewer .forge-spinner {
  animation: fadeIn ease 0.9s;
  -webkit-animation: fadeIn ease 0.9s;
  position: absolute;
  left: 50%;
  top: 50%;
  visibility: visible;
  width: min-content;
  text-align: center; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.adsk-viewing-viewer .forge-spinner svg {
  transform: rotateZ(90deg); }

.adsk-viewing-viewer .forge-spinner img {
  padding-top: 50px;
  transform: scale(0.7); }

.adsk-viewing-viewer .forge-spinner .light-blue {
  fill: #65bee7;
  fill-rule: nonzero;
  opacity: 0; }

.adsk-viewing-viewer .forge-spinner .dark-blue {
  fill: #0895d7;
  fill-rule: nonzero;
  opacity: 0; }

.split-view-container {
  position: relative;
  left: 0;
  width: 100%;
  height: 100%; }
  .split-view-container .split-view-viewer-container {
    background-color: #e5e5e5;
    width: 100%;
    height: 100%;
    position: absolute; }
  .split-view-container .resize-handler {
    background: transparent;
    position: absolute;
    z-index: 4; }
    .split-view-container .resize-handler.vertical {
      height: 100%;
      top: 0px;
      left: 100%;
      cursor: col-resize; }
    .split-view-container .resize-handler.horizontal {
      width: 100%;
      left: 0px;
      top: 100%;
      cursor: row-resize; }
  .split-view-container .resize-handler-center-mark {
    background: #73a9e8;
    position: absolute; }
    .split-view-container .resize-handler-center-mark.vertical {
      width: 1px;
      height: 100%;
      left: 7.5px; }
    .split-view-container .resize-handler-center-mark.horizontal {
      height: 1px;
      width: 100%;
      top: 7.5px; }

.adsk-viewing-viewer .loading-spinner {
  width: 100%;
  height: 150px; }

.adsk-viewing-viewer .loading-spinner .message {
  top: 60px;
  width: 100%;
  text-align: center !important;
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  cursor: default;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute; }

.adsk-viewing-viewer .loading-spinner .path {
  width: 140px;
  height: 140px;
  border: none;
  left: calc(50% - 70px);
  position: absolute;
  transform-origin: 50% 50%;
  animation-duration: 1.5s;
  animation-name: loading-spinner-perpetual-motion;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.adsk-viewing-viewer .loading-spinner path {
  stroke-width: 5px;
  stroke-linejoin: round;
  stroke-linecap: round; }

@keyframes loading-spinner-perpetual-motion {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

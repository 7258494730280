.adsk-viewing-viewer .adsk-button {
  position: relative;
  float: left;
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 6px 6px 6px 6px;
  margin: 4px 4px 4px 4px;
  border: 1px solid rgba(34, 34, 34, 0);
  border-radius: 4px; }

.adsk-viewing-viewer .adsk-button-icon {
  display: inline-block;
  padding-top: 3px;
  font-size: 24px;
  line-height: 1;
  /* legacy: for icons as background images */
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center; }

.adsk-viewing-viewer .adsk-button.disabled {
  opacity: 0.4;
  -ms-filter: "alpha(opacity=40)";
  filter: alpha(opacity=40); }

.adsk-viewing-viewer .adsk-button.collapsed {
  display: none; }

/* Label button */
.adsk-viewing-viewer .adsk-toolbar .adsk-label-button {
  width: auto;
  margin: 3px 3px 3px 1px;
  vertical-align: middle;
  opacity: 1; }

.adsk-viewing-viewer .adsk-toolbar.adsk-toolbar-vertical .adsk-label-button {
  width: 28px;
  height: auto;
  margin: 1px 3px 3px 3px; }

.adsk-viewing-viewer .adsk-toolbar .adsk-label-button label {
  display: block;
  font-size: 12px;
  padding: 8px 10px;
  border-radius: 3px;
  cursor: pointer; }

.adsk-viewing-viewer .adsk-toolbar.adsk-toolbar-vertical .adsk-label-button label {
  writing-mode: sideways-lr;
  padding: 10px 8px; }

.adsk-viewing-viewer .treeview.on-demand {
  width: 100%; }

.adsk-viewing-viewer .scroller {
  left: 0px;
  width: 100%;
  /* width: auto; */
  position: absolute; }

.adsk-viewing-viewer .treeview .group {
  position: relative;
  font-size: 14px;
  color: #333333;
  display: block;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none; }

.adsk-viewing-viewer .treeview .model-div {
  margin: 0;
  padding: 0;
  border: 0; }

.adsk-viewing-viewer .treeview .group lmvheader:hover,
.adsk-viewing-viewer .treeview .leaf lmvheader:hover {
  cursor: pointer; }

.adsk-viewing-viewer .treeview .group label:hover,
.adsk-viewing-viewer .treeview .leaf label:hover {
  text-decoration: underline;
  cursor: pointer; }

.adsk-viewing-viewer .treeview lmvheader {
  width: 100%;
  margin-bottom: 1px;
  padding: 6px;
  white-space: nowrap;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative; }

.adsk-viewing-viewer .treeview .leaf {
  position: relative;
  display: inline;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none; }

.adsk-viewing-viewer .treeview leaf > lmvheader > label,
.adsk-viewing-viewer .treeview group > lmvheader > label,
.adsk-viewing-viewer .treeview .leaf > lmvheader > label,
.adsk-viewing-viewer .treeview .group > lmvheader > label {
  pointer-events: none; }

.adsk-viewing-viewer .treeview .group.selected > lmvheader,
div.leaf.selected > lmvheader {
  background-color: #e7e7e7; }

.adsk-viewing-viewer .treeview .group.selected .leaf {
  background-color: #e7e7e7; }

.adsk-viewing-viewer .treeview .group.selected,
.leaf.selected {
  border-left: 3px solid #999999;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s ease; }

/* Bootstrap Fix */
.adsk-viewing-viewer .treeview label {
  width: calc(100% - 50px);
  margin-bottom: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.adsk-viewing-viewer .treeview .leaf label {
  width: calc(100% - 30px);
  margin-bottom: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.adsk-viewing-viewer .treeview lmvheader {
  line-height: normal; }

.adsk-viewing-viewer .model-structure-panel .lmv-no-properties {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 6px 6px 6px 12px;
  width: calc(100% - 18px); }

.adsk-viewing-viewer .model-structure-panel .tree-loading-spinner {
  position: absolute;
  top: 0;
  margin-top: 40px; }

.adsk-viewing-viewer .docking-panel.menu {
  width: auto;
  height: auto;
  min-width: 120px;
  min-height: 0;
  padding: 0;
  z-index: 20;
  position: absolute; }

.adsk-viewing-viewer .docking-panel.menu .menu-item {
  height: 36px;
  position: relative;
  display: table-row;
  margin-bottom: 1px;
  cursor: pointer;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.adsk-viewing-viewer.touch .docking-panel.menu .menu-item {
  padding: 20px; }

.adsk-viewing-viewer .docking-panel.menu .menu-ttem:last-child {
  margin-bottom: 0; }

.adsk-viewing-viewer .docking-panel.menu .menu-item-text {
  display: table-cell;
  white-space: nowrap;
  vertical-align: middle;
  padding: 5px 10px 5px 10px; }

.adsk-viewing-viewer .docking-panel.menu .menu-item-icon {
  display: table-cell;
  float: left;
  padding: 5px 0px 5px 10px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 18px;
  width: 100%;
  height: 100%; }

.adsk-viewing-viewer .docking-panel.menu .menu-item-shortcut {
  display: table-cell;
  float: right;
  padding: 5px 5px 5px 0px;
  margin-bottom: 1px;
  cursor: pointer;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.adsk-viewing-viewer .docking-panel.menu .menu-item-expand {
  position: relative;
  top: 0px;
  padding: 0px 10px;
  background-repeat: no-repeat;
  display: table-cell;
  background-position: center; }

.adsk-viewing-viewer .property-panel {
  top: 180px;
  left: 220px;
  width: 300px;
  height: 250px;
  min-width: 250px;
  min-height: 100px;
  border-color: transparent; }

.adsk-viewing-viewer .property-panel .treeview {
  margin-left: 0px;
  padding: 0;
  overflow: hidden; }

.adsk-viewing-viewer .property-panel .treeview group {
  left: 0px;
  width: 100%; }

.adsk-viewing-viewer .property-panel .no-properties {
  display: inline-block;
  padding: 6px 6px 6px 12px;
  width: calc(100% - 18px); }

.adsk-viewing-viewer .property-panel .treeview leaf {
  margin-left: 0; }

.adsk-viewing-viewer .property-panel .category-name,
.adsk-viewing-viewer .property-panel .property-name,
.adsk-viewing-viewer .property-panel .property-value {
  white-space: normal;
  vertical-align: middle; }

.adsk-viewing-viewer .property-panel .category-name {
  top: -4px;
  display: inline-block;
  position: relative;
  vertical-align: middle; }

.adsk-viewing-viewer .property-panel .property-name {
  top: -4px;
  padding-left: 28px;
  width: 40%;
  display: table-cell;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  max-width: 10px;
  /* Stub value, small enough to work along with table-cell's percentage width */
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis; }

.adsk-viewing-viewer .property-panel .property-value {
  width: 60%;
  height: 100%;
  display: table-cell;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  font-weight: normal;
  vertical-align: middle;
  position: relative;
  padding-left: 8px; }

.adsk-viewing-viewer .property-panel .separator {
  display: table-cell;
  width: 1px; }

.adsk-viewing-viewer .property-panel .treeview lmvheader {
  padding-left: 13px;
  display: table; }

.adsk-viewing-viewer .property-panel .treeview leaf > lmvheader {
  padding-top: 0;
  padding-bottom: 0; }

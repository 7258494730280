.adsk-viewing-viewer .switch-slider-row {
  cursor: pointer; }

.adsk-viewing-viewer .switch {
  position: relative;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 2px;
  width: 31px;
  height: 8px;
  pointer-events: none;
  display: inline-block; }

.adsk-viewing-viewer .switch input {
  display: none; }

.adsk-viewing-viewer .switch .slider {
  width: 16px;
  height: 2px;
  left: 15px;
  top: 5px;
  -webkit-transition: .4s;
  transition: .4s; }

.adsk-viewing-viewer .switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0px;
  top: 3px;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%; }

.adsk-viewing-viewer .switch input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px); }

.adsk-viewing-viewer .table-button {
  width: 80%;
  height: auto;
  text-align: center;
  color: #fefffe;
  border: 1px solid #fefffe; }

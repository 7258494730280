.adsk-viewing-viewer .adsk-toolbar {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
  text-align: center;
  z-index: 5;
  pointer-events: none; }

.adsk-viewing-viewer .adsk-toolbar.adsk-toolbar-vertical {
  right: 15px;
  left: unset;
  width: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 20px); }

.adsk-viewing-viewer .docking-panel {
  position: absolute;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: hidden;
  border: none;
  background: transparent;
  border-radius: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.adsk-viewing-viewer .docking-panel-title {
  padding: 11px 0px 11px 14px;
  border-radius: 5px 5px 0 0;
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  border: none;
  resize: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  cursor: move;
  position: relative;
  z-index: 1; }

.adsk-viewing-viewer .docking-panel-close {
  top: 0;
  right: 0px;
  width: 25px;
  height: 50px;
  background-position: 0px 19px;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  z-index: 1; }

.adsk-viewing-viewer .docking-panel-scroll {
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  resize: none;
  position: relative; }

.adsk-viewing-viewer .docking-panel-footer {
  bottom: 0;
  left: 0;
  height: 20px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: absolute; }

.adsk-viewing-viewer.docking-panel-resize-overlay {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  cursor: pointer;
  z-index: 999;
  background-color: transparent; }

.adsk-viewing-viewer .docking-panel-footer-resizer {
  bottom: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-x: 9px;
  background-position-y: 8px;
  position: absolute;
  cursor: pointer; }

.adsk-viewing-viewer .docking-panel-solid-container {
  background: none; }

.adsk-viewing-viewer .docking-panel-gradient-container {
  background: none; }

.adsk-viewing-viewer .docking-panel-textbox {
  text-align: right;
  width: calc(100% - 20px);
  position: relative;
  left: 0;
  outline: none;
  border-radius: 2px; }

.adsk-viewing-viewer .docking-panel-primary-button,
.adsk-viewing-viewer .docking-panel-secondary-button,
.adsk-viewing-viewer .docking-panel-tertiary-button {
  height: 34px;
  line-height: 34px;
  font-size: 12px;
  vertical-align: middle;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: bold;
  letter-spacing: -0.1px; }

.adsk-viewing-viewer .docking-panel-tertiary-button {
  margin: 7px 10px 7px 10px; }

.adsk-viewing-viewer .docking-panel-scroll.left {
  direction: rtl;
  overflow-x: hidden; }

.adsk-viewing-viewer .docking-panel-scroll.right {
  direction: ltr;
  overflow-x: hidden; }

.adsk-viewing-viewer .docking-panel-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  padding: 12px; }

.adsk-viewing-viewer .docking-panel-scroll::-webkit-scrollbar-track {
  border-radius: 0px; }

.adsk-viewing-viewer .docking-panel-scroll::-webkit-scrollbar-thumb {
  padding: 12px;
  border-radius: 1px; }

.adsk-viewing-viewer .docking-panel-version-label {
  font-size: 10px;
  margin-top: 2px;
  margin-right: 20px;
  float: right; }
